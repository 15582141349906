body {
  font-family: "Lato";
  overflow-x: hidden;
}
ul {
  margin: 0;
  padding: 0;
}
li {
  display: inline-block;
}
h3 {
  font-size: 32px;
  margin: 0 0 12px 0;
  font-weight: 300;
}
h4 {
  font-size: 16px;
}
.modal-open .modal {
  padding-top: 10%;
}
.slideshow img {
  display: none;
  cursor: pointer;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.flex_centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.padding-default {
  padding: 40px 7%;
}
.col-sm-6 {
  padding: 0px;
}

/* Extend-bootsrap  */
.float-right {
  float: right;
}
