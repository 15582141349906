#portfolio .h_portfolio {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-image: -webkit-gradient(
      linear,
      100% 0,
      0 0,
      from(#fff),
      to(#fff),
      color-stop(50%, #e8e8e8)
    )
    100% 1;
}
#portfolio .h_portfolio h3 {
  color: #17adb3;
  padding: 20px 0px 30px 0px;
  text-align: center;
}
#portfolio .bg_orange {
  background-color: rgba(230, 92, 71, 0.4);
}
#portfolio .bg_blue {
  background-color: rgba(23, 173, 179, 0.4);
}
#portfolio .bg_yellow {
  background-color: rgba(230, 173, 16, 0.4);
}

#portfolio .bg_purple {
  background-color: rgb(129 38 180 / 30%);
}

#portfolio .bg_grey {
  background-color: rgba(51, 51, 51, 0.2);
}
#portfolio .width-img-highlight {
  width: 250px !important;
  height: 210px !important;
}

#portfolio .highlighted.thumbnail_big img.width-img-highlight {
  width: 310px !important;
  height: 220px !important;
}

#portfolio .thumbnail_big {
  height: 300px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#portfolio .thumbnail_big > div {
  position: absolute;
  height: 300px !important;
  width: 25% !important;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* text-align: center;
    */
  /* margin-top: 0px;
    */
  z-index: 99;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

#portfolio .thumbnail_big > div:hover {
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
}

#portfolio .highlighted.thumbnail_big > div {
  height: 300px !important;
  width: 50% !important;
}

#portfolio .thumbnail_big > div span,
#portfolio .thumbnail_big > div a {
  font-size: 20px;
  font-weight: 300;
  opacity: 0;
  padding: 5px;
  text-align: center;
}
#portfolio .thumbnail_big > div a {
  text-decoration: none;
}
#portfolio .thumbnail_big > div .opacity-span {
  opacity: 1;
  color: #fff;
}

#portfolio .thumbnail_big > div .thumbnail-inner-container {
  text-align: center;
}

#portfolio .thumbnail_big > div .thumbnail-inner-container span,
#portfolio .thumbnail_big > div .thumbnail-inner-container a {
  display: block;
}

#portfolio .thumbnail_big > div .thumbnail-inner-container span:hover,
#portfolio .thumbnail_big > div .thumbnail-inner-container a:hover {
  text-decoration: underline;
}

#portfolio .thumbnail_big img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 230px;
  height: 190px;
}

#portfolio .highlighted.thumbnail_big img {
  width: 270px;
  height: 190px;
}

#portfolio .modal .modal-dialog {
  width: 1200px;
}
#portfolio .modal .modal-dialog .modal-content {
  display: flex;
  justify-content: center;
  height: 500px;
}
#portfolio .modal .modal-dialog .modal-content .left-modal-side {
  width: 65%;
}
#portfolio .modal .modal-dialog .modal-content .left-modal-side .slideshow {
  max-height: 498px;
  overflow: hidden;
}
#portfolio
  .modal
  .modal-dialog
  .modal-content
  .left-modal-side
  .slideshow
  .controls {
  transition: none !important;
  position: absolute;
  top: 250px;
  width: 778.69px;
  text-align: center;
  z-index: 99;
  padding-left: 25px;
}
#portfolio
  .modal
  .modal-dialog
  .modal-content
  .left-modal-side
  .slideshow
  .controls
  a {
  color: rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
#portfolio
  .modal
  .modal-dialog
  .modal-content
  .left-modal-side
  .slideshow
  .controls
  a
  i {
  font-size: 24px;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
#portfolio
  .modal
  .modal-dialog
  .modal-content
  .left-modal-side
  .slideshow
  .controls
  a
  i:hover {
  color: rgba(0, 0, 0, 0.6);
}
#portfolio
  .modal
  .modal-dialog
  .modal-content
  .left-modal-side
  .slideshow
  .controls
  .prev {
  float: left;
}
#portfolio
  .modal
  .modal-dialog
  .modal-content
  .left-modal-side
  .slideshow
  .next {
  float: right;
  padding-right: 25px;
}
#portfolio .modal .modal-dialog .modal-content .left-modal-side .slides img {
  filter: none;
  width: 100%;
  height: 498px;
}
#portfolio .modal .modal-dialog .modal-content .right-modal-side {
  width: 35%;
  padding: 30px;
}
#portfolio div div img {
  -webkit-filter: drop-shadow(0px 0px 33px rgba(51, 51, 51, 0.5));
  filter: drop-shadow(0px 0px 33px rgba(51, 51, 51, 0.5));
}
