#contact {
  background-color: #f8f8f8;
}
#contact .flex_centered .contact-title h3 {
  color: #f45a40;
}
#contact .row {
  padding: 40px 7%;
}
#contact .row div form h2 {
  margin: 45px 0px;
}
#contact .row div form > div {
  display: block;
  margin-bottom: 12px;
  transition: margin 300ms;
}

#contact .row div form > div.form-invalid {
  margin-bottom: 30px;
}

#contact .row div form > div.form-invalid.message {
  margin-bottom: 12px;
}

#contact .row div form > div:nth-child(1),
#contact .row div form > div:nth-child(2) {
  display: flex;
  align-items: center;
}

#contact .row div form > div label {
  width: 20%;
  font-size: 18px;
  font-weight: 100;
}
#contact .row div form .input-container {
  width: 80%;
  float: right;
}

#contact .row div form .submitForm input[type="submit"] {
  /* width: 100%; */
  border: none;
  padding: 12px 30px;
  color: #fff;
  background: none;
  border: none;
  outline: 0;
  text-transform: uppercase;
  font-size: 14px;
}

#contact .row div form .submitForm {
  display: flex;
  align-items: center;
  background-color: #f45a40;
  border: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: fit-content;
  margin-top: 20px;
}
#contact .row div form .submitForm:hover {
  background-color: #ef553d;
  cursor: pointer;
}

#contact .row div form .submitForm.loading input[type="submit"] {
  padding: 12px 10px 12px 30px;
}

#contact .row div form .submitForm input[type="submit"]:disabled {
  color: #868686;
  background: #cecece;
}

/* Error */

#contact .row div form .error-text {
  color: #868686;
  position: absolute;
  font-size: 13px;
}

#contact .row div form .submitForm.loading .spinner-border {
  width: 20px;
  height: 20px;
  color: #fff;
  margin-right: 8px;
}

#contact .row div form .feedback span {
  color: #333;
}
#contact .row div form > div *::-webkit-input-placeholder {
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  font-weight: 300;
}
#contact .row div form > div input {
  color: rgba(51, 51, 51, 0.5);
  font-size: 18px;
  font-weight: 400;
}
#contact .row div form > div input[type="text"] {
  padding: 5px;
}
#contact .row div form .feedback {
  display: block;
  margin: -10px 0px 15px 0px;
}

#contact .row div form .message {
  display: inline-block;
  width: 100%;
}

#contact .row div form .message .input-container {
  width: 100%;

  color: rgba(51, 51, 51, 0.5);
  font-size: 20px;
  font-weight: 400;
}

#contact .row div form .message .input-container textarea {
  min-height: 75px;
}
#footer div {
  background-color: #333;
}
#footer div .footer h3 {
  font-size: 14px;
  color: #bbb;
}
