#skills {
  padding-bottom: 73px;
}
#skills div .desc {
  padding-top: 80px;
  text-align: center;
  max-width: 788px;
  width: 90%;
  font-weight: 300;
}
#skills div .desc h3 {
  color: #e6ad10;
}
#skills div .desc p {
  line-height: 32px;
  font-size: 18px;
  margin: 0px;
}
#skills .skills-pic-and-desc {
  padding-top: 50px;
}
#skills .skills-pic-and-desc .pic_computer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 500px;
}
#skills .skills-pic-and-desc .pic_computer .pic-computer_border_bottom {
  border-width: 0px 0px 1px;
  border-style: solid;
  border-image: -webkit-gradient(
      linear,
      100% 0,
      0 0,
      from(#fff),
      color-stop(0.5, #e8e8e8),
      to(#fff)
    )
    100% 1;
  display: flex;
  justify-content: center;
  width: 640px;
}
#skills .skills-pic-and-desc .skills-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 500px;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text {
  font-size: 18px;
  font-weight: 300;
  display: block;
  padding: 5px;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .orange {
  background-color: #e65c47;
  color: #fff;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .cian {
  background-color: #17adb3;
  color: #fff;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .yellow {
  background-color: #e6ad10;
  color: #fff;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .grey {
  background-color: #333;
  color: #fff;
}

#skills
  .skills-pic-and-desc
  .skills-title
  .skills-align
  .skill-container.angular {
  border-top: 1px solid #f2f2f2;
  margin-top: 15px;
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .angular {
  border: 1px solid #dd0031;
  color: #dd0031;
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text.angular {
  margin-top: 10px;
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .react {
  border: 1px solid #5fb7ff;
  color: #5fb7ff;
  background-color: #333;
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text.react {
  margin-bottom: 10px;
}

#skills
  .skills-pic-and-desc
  .skills-title
  .skills-align
  .skill-container.react {
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 15px;
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .php {
  border: 1px solid #6c7eb7;
  color: #6c7eb7;
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text.php {
  margin-top: 10px;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .rest {
  border: 1px solid #33b5e6;
  color: #33b5e6;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .json {
  border: 1px solid #4d9945;
  color: #4d9945;
}
#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .wp {
  border: 1px solid rgba(51, 51, 51, 0.5);
}

#skills .skills-pic-and-desc .skills-title .skills-align .skill-text .joomla {
  border: 1px solid #5191ce;
  color: #f44321;
}

#skills
  .skills-pic-and-desc
  .skills-title
  .skills-align
  .skill-text
  .skill-title {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}
#skills .skills-pic-and-desc .skills-title .back-end {
  padding-top: 10px;
}
#skills .skills-pic-and-desc .skills-title .cms {
  padding-top: 10px;
}
