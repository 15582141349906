.menubar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f8f8f8;
}
.menubar .logo-element .logo {
  float: left;
  width: 40px;
}
.menubar .menu-elements {
  float: right;
  text-transform: uppercase;
  font-weight: bold;
}
.menubar .menu-elements li a {
  color: #e65c47;
  text-decoration: none;
  padding-left: 70px;
  font-size: 13px;
}
