

@font-face {
  font-family: "Lato";
  src: local("Lato"),
   url("./fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
 }


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
