.about {
  padding-bottom: 137px;
}
.about .intro {
  padding-top: 80px;
  text-align: center;
  max-width: 788px;
  width: 90%;
  font-weight: 300;
}
.about .intro h3 {
  color: #e65c47;
}
.about .intro p {
  line-height: 32px;
  font-size: 18px;
  margin: 0px;
}
.about .intro p:first-of-type {
  margin-bottom: 10px !important;
}
