.modal .modal-dialog .modal-content .left-modal-side {
  width: 65%;
}

.modal .modal-dialog .modal-content .right-modal-side {
  width: 35%;
  padding: 30px;
}

.modal .modal-dialog .modal-content .carousel-control-next,
.modal .modal-dialog .modal-content .carousel-control-prev {
  color: #000;
}

.modal .modal-dialog .modal-content .sr-only {
  display: none;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtbGVmdC1maWxsIiB2aWV3Qm94PSIwIDAgMTYgMTYiPgogIDxwYXRoIGQ9Im0zLjg2IDguNzUzIDUuNDgyIDQuNzk2Yy42NDYuNTY2IDEuNjU4LjEwNiAxLjY1OC0uNzUzVjMuMjA0YTEgMSAwIDAgMC0xLjY1OS0uNzUzbC01LjQ4IDQuNzk2YTEgMSAwIDAgMCAwIDEuNTA2eiIvPgo8L3N2Zz4=");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktY2FyZXQtcmlnaHQtZmlsbCIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJtMTIuMTQgOC43NTMtNS40ODIgNC43OTZjLS42NDYuNTY2LTEuNjU4LjEwNi0xLjY1OC0uNzUzVjMuMjA0YTEgMSAwIDAgMSAxLjY1OS0uNzUzbDUuNDggNC43OTZhMSAxIDAgMCAxIDAgMS41MDZ6Ii8+Cjwvc3ZnPg==");
}

.length-1 .carousel-control-prev-icon {
  display: none;
}

.length-1 .carousel-control-prev-icon:hover {
  cursor: initial;
}
