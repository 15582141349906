.modal .modal-dialog {
  width: 1200px;
  max-width: none;
}

.modal .modal-content {
  flex-direction: initial;
}

.right-modal-side p {
  font-size: 14px;
}

.right-modal-side {
  padding: 30px;
}

.description {
  white-space: pre-line;
}
