/* SLIDER */
.slider {
  overflow: hidden;
  height: 500px;
}
.slider .cover-image {
  width: 60%;
}
.slider .cover-image .bg {
  /* background-image: url(../img/bg2.png); */
  height: 500px;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider .cover-image .bg .full-height .text {
  transition: width 0.1s;
  width: 390px;
}
.slider .cover-image .bg .full-height .text span {
  display: block;
  text-transform: uppercase;
  color: #fff;
  font-weight: 100;
}
.slider .cover-image .bg .full-height .text .letter-i {
  display: inline-block;
  margin-left: -12px;
}
.slider .cover-image .bg .full-height .text .letter-i span {
  font-size: 230px;
}
.slider .cover-image .bg .full-height .text .letter-name-and-m {
  display: inline-block;
  margin-top: -20px;
}
.slider .cover-image .bg .full-height .text .letter-name-and-m .m-word {
  font-size: 129px;
}
.slider .cover-image .bg .full-height .text .letter-name-and-m .name-word {
  font-size: 53px;
  line-height: 0px;
  display: block;
  padding-left: 7px;
  transition: width 2s;
}
.slider .cover-image .bg .full-height .text .letter-ap {
  display: inline-block;
  line-height: 240px;
}
.slider .cover-image .bg .full-height .text .letter-ap span {
  font-size: 230px;
  margin: -34px;
}
.slider .cover-image .bg .full-height .text .huge-word {
  font-size: 148px;
  line-height: 130px;
  margin-left: -8px;
}
.slider .cover-image .bg .full-height .text .mid-word {
  font-size: 31px;
  display: inline-block;
  padding-right: 10px;
  transition: width 2s;
  display: none;
  line-height: 47px;
}
.slider .cover-image .bg .full-height .text .form-game {
  display: none;
  width: 350px;
}
.slider .cover-image .bg .full-height .text .form-game input[type="text"] {
  font-size: 31px;
  line-height: 49px;
  width: 400px;
  background: none;
  border-style: solid;
  border: none;
  color: #fff;
  font-weight: 300;
}
.slider
  .cover-image
  .bg
  .full-height
  .text
  .form-game
  *::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.3);
}
.slider .cover-image .bg .full-height .text .small-word {
  font-size: 21.5px;
  font-weight: 700;
  color: #e65c47;
}
.slider .cover-image .bg .full-height .text .small-word .a_char {
  font-size: 27px;
  font-weight: 700;
  color: #e65c47;
  display: inline-block;
}
.slider .cover-image .bg .full-height .text .subtitle {
  font-size: 18px;
  font-style: italic;
  text-transform: none;
}
.slider .cover-image .bg .full-height .text .subtitle .orange {
  color: #e65c47;
  text-transform: none;
  display: inline-block;
  font-weight: 400;
}
.slider .cover-image .bg .full-height .text button {
  background-color: #e65c47;
  color: #fff;
  border: none;
  outline: 0;
  text-transform: uppercase;
  padding: 12px 30px;
  margin-top: 30px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slider .cover-image .bg .full-height .text button:hover {
  background-color: #ef553d;
}
.slider .tile-images {
  width: 40%;
}
.slider .tile-images .padding-top-left {
  padding: 0px 5px 5px 10px;
}
.slider .tile-images .padding-top-right {
  padding: 0px 10px 5px 5px;
}
.slider .tile-images .padding-bottom-left {
  padding: 5px 5px 0px 10px;
}
.slider .tile-images .padding-bottom-right {
  padding: 5px 10px 0px 5px;
}
.slider .tile-images .margin {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider .tile-images h4 {
  text-align: center;
  margin: 5px;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 120px !important;
}
.slider .tile-images img {
  width: 90px;
  height: 120px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slider .tile-images .tile-box {
  width: 50%;
}

.slider .tile-images .icon_cv {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
.slider .tile-images .icon_cv_pic {
  background-color: #e6ad10;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slider .tile-images .icon_cv_pic:hover {
  background-color: #cc990e;
}
.slider .tile-images .img_and_text {
  text-align: center;
}
.slider .tile-images .highlighted {
  width: 120px;
  height: 150px;
  transition: width 0.5s;
  transition: height 0.5s;
}
.slider .tile-images .h4-margin-hover {
  font-size: 20px;
}
.slider .tile-images .darker-highlight {
  background-color: #d8a310 !important;
}
.slider .tile-images .icon_pf {
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 100%;
}
.slider .tile-images .icon_pf_pic {
  background-color: #17adb3;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.slider .tile-images .icon_pf_pic:hover {
  background-color: #169ba0;
}
